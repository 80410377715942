
function About() {
    return (
        <div>
            <div>
                <div class="breadcumb-area d-flex align-items-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="breacumb-content">
                                    <div class="breadcumb-title">
                                        <h1>O nas</h1>
                                    </div>
                                    <div class="breadcumb-content-text">
                                        <a href="/"> START<i class="fa fa-angle-right"></i><span>O nas</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="about-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-3">
                                <div class="about-thumb faq-img-animation">
                                    <img src="assets/projects/dashboard.png" alt="CRM" />
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-9">

                                <div class="choos-single-box">
                                    <div class="section-title">
                                        <div class="main-title">
                                            <h1>O</h1>
                                        </div>
                                        <div class="sub-title">
                                            <h5>-</h5>
                                            <h2>O założycielu</h2>
                                            <p>System do zarządania Twoją Firmą pozwoli Ci na bieżąco monitorować interesujące Ciebie obszary Twojego biznesu.</p>
                                        </div>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Automatyzacja procesów</h3>
                                        <p>Zautomatyzuj powtarzalne procesy w Twoim przedsiębiorstwie i zaoszczędź czas.</p>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Baza klientów</h3>
                                        <p>Przeglądaj i zarządzaj bazą klientów w jednym miejscu. </p>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Raporty dla managerów</h3>
                                        <p>Analizuj aktywność Twoich pracowników poprzez dostosowane raporty.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>);
}

export default About;
